import React from 'react';

export default class Footer extends React.Component {
    render() {
        return (<footer id="contact">
            <div className="footer-bottom slim">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <p className="copyright">Copyright © 2023 <a href='https://develblockchain.com'>Devel Blockchain</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>)
    }
}